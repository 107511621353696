<template>
  <div class="game-container">
    <DragGame
      :envelopeList="envelopeList"
      :mailboxList="mailboxList"
      :dragNum="dragNum"
      @randomOrder="handleRandomOrder"
      @socketRandom="handleSocketRandom"
    />
  </div>
</template>

<script>
import DragGame from "@/components/Course/GamePage/DragGame";
export default {
  data() {
    return {
      dragNum: 4,
      envelopeList: [
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/L2/lesson-13-envelope-le.svg"),
          isDispear: false,
          key: 1,
          index: 1,
        },
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/L2/lesson-13-envelope-mei.svg"),
          isDispear: false,
          key: 2,
          index: 2,
        },
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/L2/lesson-13-envelope-zuotian.svg"),
          isDispear: false,
          key: 3,
          index: 3,
        },
         {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/L2/lesson-13-envelope-qima.svg"),
          isDispear: false,
          key: 4,
          index: 4,
        },
      ],
      mailboxList: [
        {
          pinyin: "le",
          hanzi: "了",
          value: 1,
        },
        {
          pinyin: "méi ",
          hanzi: "没",
          value: 2,
        },
        {
          pinyin: "zuótiān",
          hanzi: "昨天",
          value: 3,
        },
         {
          pinyin: "qímǎ",
          hanzi: "骑马",
          value: 4,
        },
      ],
    };
  },
  components: {
    DragGame,
  },
  methods: {
    handleSocketRandom(value) {
      this.envelopeList = value;
    },
    handleRandomOrder() {
      this.envelopeList.sort(function () {
        return Math.random() - 0.5;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











