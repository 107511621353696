<template>
  <div class="game-container">
    <ChooseImgDog
      :allImgList="allImgList"
      :buttonList="buttonList"
      :answerList="answerList"
      :mailboxList="mailboxList"
      :dragNum="dragNum"
      :durationArr="durationArr"
    />
  </div>
</template>

<script>
import ChooseImgDog from "@/components/Course/GamePage/ChooseImgDog";
export default {
  data() {
    return {
      dragNum: 4,
      answerList: [1,2,3,4],
      durationArr: [0, 0.5, 0.7, 0.9, 1],
      allImgList: [
        {
          imgList:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "xǐhuan",
              hanZi: "喜欢 ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:3,
              color:'#fff',
              isDispear: false,
              key: 3,
              index: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "Dìdi",
              hanZi: "弟弟  ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:1,
              color:'#fff',
              isDispear: false,
              key: 1,
              index: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "bù",
              hanZi: "  不   ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:2,
              color:'#fff',
              isDispear: false,
              key: 2,
              index: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "qímǎ.",
              hanZi: "    骑马。 ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:4,
              color:'#fff',
              isDispear: false,
              key: 4,
              index: 4,
            },
          ]
        },
        {
          imgList:[
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "chī le",
              hanZi: "    吃了  ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:3,
              color:'#fff',
              isDispear: false,
              key: 3,
              index: 3,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: " Wǒ",
              hanZi: "我    ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:1,
              color:'#fff',
              isDispear: false,
              key: 1,
              index: 1,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "zuótiān ",
              hanZi: "   昨天  ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:2,
              color:'#fff',
              isDispear: false,
              key: 2,
              index: 2,
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/island-3.svg`),
              rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
              isStartPlaying:null,
              pinYin: "bāozi.",
              hanZi: " 包子。   ",
              audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
              id:4,
              color:'#fff',
              isDispear: false,
              key: 4,
              index: 4,
            }
          ]
        }
      ],
      mailboxList:[
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-1.svg"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
          isStartPlaying:null,
          audioSrc: require("@/assets/audio/chapter_1/ni-hao.mp3"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-1.svg"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
          isStartPlaying:null,
          audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
      ],
    };
  },
 
  components: {
    ChooseImgDog,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>